import * as yup from 'yup'
import richContent from './richContent'

export default yup.object().shape({
  title: yup
    .string()
    .typeError('A title is required to make a post')
    .min(3, 'Post Titles should be longer than 3 characters'),
  content: richContent(),
})
