import { useSWRConfig } from 'swr'
import { useCacheKey } from '../components/context/CacheKey'
import postCache from './cache/postCache'
import { createPost, deletePost, updatePost } from './usePost'

export default function usePostCrud() {
  const { cache, mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  return {
    updatePost: async (post) => {
      const response = await updatePost(post)
      postCache(cache, mutate, cacheKey, post.slug).update(() => ({
        ...response.post,
      }))
    },
    deletePost: async (post) => {
      await deletePost(post.slug)
      postCache(cache, mutate, cacheKey, post.slug).remove()
    },
    createPost: async (post) => {
      const response = await createPost(post)
      postCache(cache, mutate, cacheKey).add(response.post)

      return response
    },
  }
}
