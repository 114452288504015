import { useSWRConfig } from 'swr'
import { useCacheKey } from '../components/context/CacheKey'
import eventCache from './cache/eventCache'
import postCache from './cache/postCache'
import { createPost, deletePost, updatePost } from './usePost'

export default function useReplyCrud(parentSlug) {
  const { cache, mutate } = useSWRConfig()
  const cacheKey = useCacheKey()

  return {
    createReply: async (reply) => {
      let response = null
      try {
        response = await createPost(reply)
      } catch (error) {
        return
      }

      switch (reply.postable_type) {
        case 'Post':
          postCache(cache, mutate, cacheKey, parentSlug).update((post) => {
            post.reply_count = (post?.reply_count || 0) + 1
            post?.replies.push(response.post)
          })
          break
        case 'Event':
          eventCache(cache, mutate, cacheKey, parentSlug).update((event) => {
            event.reply_count = (event?.reply_count || 0) + 1
            event?.replies.push(response.post)
          })
          break

        default:
          throw new Error('Could not create post reply')
      }

      return response
    },

    updateReply: async (reply) => {
      let response = null
      try {
        response = await updatePost(reply)
      } catch (error) {
        return
      }

      switch (reply.type) {
        case 'Post':
          postCache(cache, mutate, cacheKey, parentSlug).update((post) => {
            const replyIndex = post?.replies.findIndex(
              (r) => r.slug === reply.slug
            )
            post.replies[replyIndex] = response.post
          })
          break
        case 'Event':
          eventCache(cache, mutate, cacheKey, parentSlug).update((event) => {
            const replyIndex = event?.replies.findIndex(
              (r) => r.slug === reply.slug
            )
            event.replies[replyIndex] = response.post
          })
          break

        default:
          throw new Error('Could not update post reply')
      }
    },
    deleteReply: async (reply) => {
      try {
        await deletePost(reply.slug)
      } catch (error) {
        return
      }

      switch (reply.type) {
        case 'Post':
          postCache(cache, mutate, cacheKey, parentSlug).update((post) => {
            const replyIndex = post?.replies.findIndex(
              (r) => r.slug === reply.slug
            )
            post.reply_count -= 1
            post?.replies.splice(replyIndex, 1)
          })
          break
        case 'Event':
          eventCache(cache, mutate, cacheKey, parentSlug).update((event) => {
            const replyIndex = event?.replies.findIndex(
              (r) => r.slug === reply.slug
            )
            event.reply_count -= 1
            event?.replies.splice(replyIndex, 1)
          })
          break

        default:
          throw new Error('Could not delete post reply')
      }
    },
  }
}
