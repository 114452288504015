import produce from 'immer'

/*
 * Implements adding, updating, and removing posts in cache.
 *
 * cacheKey is required for all operations and references the cache key
 * of the feed.
 *
 * postSlug is not required for add, and required for update/remove
 */
export default function postCache(cache, mutate, cacheKey, postSlug) {
  return {
    add(post) {
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null) {
            return
          }
          if (pages.length === 0) {
            pages[0] = { items: [] }
          }
          pages[0].items.unshift(post)
        }),
        false
      )
    },

    update(updater) {
      mutate(
        `/api/posts/${postSlug}.json`,
        produce((data) => {
          if (data?.post == null) {
            return
          }
          data.post = produce(data.post, updater)
        }),
        false
      )
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null || pages.length === 0) {
            return
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < pages.length; i++) {
            const postIndex = pages[i].items.findIndex(
              (p) => p.slug === postSlug
            )
            if (postIndex > -1) {
              pages[i].items[postIndex] = produce(
                pages[i].items[postIndex],
                updater
              )
              return
            }
          }
        }),
        false
      )
    },

    remove() {
      cache.delete(`/api/posts/${postSlug}.json`)
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null || pages.length === 0) {
            return
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < pages.length; i++) {
            const postIndex = pages[i].items.findIndex(
              (p) => p.slug === postSlug
            )
            if (postIndex > -1) {
              pages[i].items.splice(postIndex, 1)
              return
            }
          }
        }),
        false
      )
    },
  }
}
