import Image from 'next/image'

const StampPopout = ({
  setRef,
  style,
  avatar,
  name,
  description,
  onClick,
  stampableId,
  canAwardStamp,
  ...props
}) => {
  const containerStyle = {
    zIndex: 100,
    ...style,
  }

  return (
    <div
      ref={setRef}
      style={containerStyle}
      className="rounded-2xl bg-alt-bg w-48 max-w-200 flex flex-col justify-center items-center"
      {...props}
    >
      <div className="pt-2 pb-3">
        {avatar && (
          <Image
            key={`${name}-stamp-popout-avatar`}
            src={avatar}
            alt={`${name} stamp avatar`}
            width={80}
            height={80}
            style={{
              maxWidth: 80,
              maxHeight: 80,
            }}
          />
        )}
      </div>
      <div className="w-full px-5 py-4 bg-white rounded-xl text-center">
        <p className="font-bold mb-2">{name}</p>
        <p className="text-tiny mb-4">{description}</p>
        <button
          onClick={onClick}
          className="text-topic-blue text-micro"
          href="app.communitylab.app"
        >
          {stampableId && canAwardStamp
            ? 'Award an Impact Stamp'
            : 'What are Impact Stamps?'}
        </button>
      </div>
    </div>
  )
}

export default StampPopout
