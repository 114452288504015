import Image from 'next/image'
import { useEffect, useState } from 'react'
import useStamps from '../../../lib/useStamps'
import EmptyView from '../../common/EmptyView'
import BaseModal from '../../common/modal/BaseModal'
import SelectedStamp from './SelectedStamp'

const StampAwardModal = ({
  isOpen,
  onRequestClose,
  stampableId,
  stampableType,
  item,
}) => {
  const [selectedStamp, setSelectedStamp] = useState()
  const { stamps } = useStamps({ items: 20 }, isOpen)

  useEffect(() => {
    if (!selectedStamp && stamps) {
      setSelectedStamp(stamps[0])
    }
  }, [stamps])

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose()
        setSelectedStamp(null)
      }}
      closeIcon
      title={stampableId ? 'Award an Impact Stamp!' : 'What’s an Impact Stamp?'}
      titleStyle="w-full text-center"
    >
      {stamps ? (
        <div className="w-full px-7">
          <p className="opacity-70 pb-7">
            Award another member's post with an Impact Stamp to show your
            appreciation and thank them for their contribution.
            {stampableId
              ? ' Give it a try!'
              : ' Here’s Stamps that we currently have:'}
          </p>

          <div className="flex flex-row justify-between items-start max-h-132">
            <div className="flex flex-row flex-wrap gap-4 w-full max-w-256 max-h-132 scrollbar-hide overflow-y-scroll">
              {stamps?.map((stamp) => (
                <button
                  key={stamp?.slug}
                  onClick={() => setSelectedStamp(stamp)}
                  className={`p-3 rounded-lg ${
                    selectedStamp?.slug === stamp?.slug
                      ? 'bg-alt-bg'
                      : 'transparent'
                  } hover:bg-alt-bg`}
                >
                  {stamp?.logo && (
                    <Image
                      key={`${stamp?.name}-stamp-award-modal-avatar`}
                      src={stamp?.logo}
                      alt={`${stamp?.name} stamp avatar`}
                      width={90}
                      height={90}
                      style={{
                        maxWidth: 90,
                        height: 90,
                      }}
                    />
                  )}
                </button>
              ))}
            </div>
            <div className="flex flex-col justify-start border-l-2 border-gray-100 items-center text-center w-full max-w-256">
              {selectedStamp && (
                <SelectedStamp
                  item={item}
                  stampableId={stampableId}
                  stampableType={stampableType}
                  selectedStamp={selectedStamp}
                  onRequestClose={onRequestClose}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <EmptyView title="No Impact Stamps found" defaultAccessory />
      )}
    </BaseModal>
  )
}

export default StampAwardModal
