import useSWR from 'swr'
import createFormData from './createFormData'
import fetchJson from './fetchJson'
import useMutators from './useMutators'

export default function usePost(slug, shouldFetch = true) {
  const url = slug != null && shouldFetch ? `/api/posts/${slug}.json` : null
  const { error, data, mutate: mutatePost, isValidating } = useSWR(url)
  const post = data?.post

  const api = useMutators(mutatePost, {
    updatePost,
    deletePost,
  })

  return {
    post,
    mutatePost,
    isValidating,
    error,
    ...api,
    createPost,
  }
}

export async function createPost(post) {
  const formData = createFormData({ post })

  return await fetchJson(`/api/posts.json`, {
    method: 'POST',
    body: formData,
  })
}

export async function updatePost(post) {
  const formData = createFormData({ post })

  const identifier = post.slug || post.id
  return await fetchJson(`/api/posts/${identifier}.json`, {
    method: 'PUT',
    body: formData,
  })
}

export async function deletePost(postSlug) {
  return await fetchJson(`/api/posts/${postSlug}.json`, {
    method: 'DELETE',
  })
}
