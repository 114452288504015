import { useState } from 'react'
import useStamps from '../../lib/useStamps'
import Icon from '../common/Icon'
import StampAwardModal from './StampAwardModal/StampAwardModal'
import StampCounter from './StampCounter/StampCounter'
import StampListModal from './StampListModal'

const AwardStampRow = ({
  itemStamps,
  stampableId,
  stampableType,
  canAwardStamp,
  item,
}) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false)
  const [awardModalIsOpen, setAwardModalIsOpen] = useState(false)
  const [listModalIsOpen, setListModalIsOpen] = useState(false)
  const shouldFetch =
    awardModalIsOpen || listModalIsOpen || (itemStamps && Object.keys(itemStamps)?.length > 0)
  const { stamps } = useStamps({ items: 20 }, shouldFetch)

  if (itemStamps && Object.keys(itemStamps)?.length === 0 && !canAwardStamp) {
    return null
  }

  return (
    <>
      <div className="flex flex-row flex-wrap gap-3 my-4 justify-start items-center">
        {itemStamps && Object.keys(itemStamps)?.length > 0 && (
          <div
            onClick={() => setListModalIsOpen(true)}
            onKeyDown={() => setListModalIsOpen(true)}
            tabIndex={0}
            role="button"
            className="cursor-pointer"
          >
            <p className="text-sm opacity-50">Awarded with:</p>
          </div>
        )}
        {itemStamps &&
          Object.keys(itemStamps)?.length > 0 &&
          Object.keys(itemStamps)?.map((key) => {
            const stamp = stamps?.find((s) => s?.id === parseInt(key))

            if (!stamp) {
              return null
            }

            return (
              <StampCounter
                key={`${stamp?.slug}-${stampableId}`}
                name={stamp?.name}
                avatar={stamp?.logo}
                description={stamp?.description}
                count={itemStamps[key]?.count}
                stampableId={stampableId}
                onClick={() => setAwardModalIsOpen(true)}
                canAwardStamp={canAwardStamp}
              />
            )
          })}
        {canAwardStamp && (
          <button
            onClick={() => setAwardModalIsOpen(true)}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            className="px-5 py-2 text-offBlack hover:text-white text-micro bg-alt-bg hover:bg-topic-green rounded-3xl flex flex-row justify-center items-center gap-1"
          >
            <Icon
              name="gift-stamp"
              size={15}
              color={isButtonHovered ? 'white' : 'offBlack'}
            />
            <span>Award a Stamp</span>
          </button>
        )}
      </div>
      <StampAwardModal
        isOpen={awardModalIsOpen}
        onRequestClose={() => setAwardModalIsOpen(false)}
        stampableId={canAwardStamp ? stampableId : null}
        stampableType={stampableType}
        item={item}
      />
      <StampListModal
        isOpen={listModalIsOpen}
        onRequestClose={() => setListModalIsOpen(false)}
        setAwardModalIsOpen={setAwardModalIsOpen}
        stampableId={stampableId}
        stampableType={stampableType}
        item={item}
      />
    </>
  )
}

export default AwardStampRow
