import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import intlFormat from 'date-fns/intlFormat'
import getUserLocale from 'get-user-locale'
import Link from 'next/link'
import useMemoizedPathFor from '../../lib/pathFor'
import useUser from '../../lib/useUser'
import CLAvatar from '../common/CLAvatar'
import ProBadge from '../common/ProBadge'
import ActivityContent from './ActivityContent/ActivityContent'
import PostActions from './PostActions'
import RenderResource from './RenderResource'

const Reply = ({
  item,
  parentSlug,
  parentType,
  groupId,
  disableReplyActions = false,
  collapsable = true,
}) => {
  const { user, isGroupManager } = useUser()
  const userLocale = getUserLocale()
  const isManager = isGroupManager(groupId)
  const profileHref = useMemoizedPathFor('user', item?.user)

  const fullDateTime = intlFormat(
    new Date(item?.created_at),
    {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    {
      locale: userLocale,
    }
  )

  const fromNowTime = formatDistanceToNowStrict(new Date(item?.created_at), {
    includeSeconds: true,
    addSuffix: true,
  })

  return (
    <div className="flex flex-row w-full my-3 flex-shrink-0">
      <div>
        <CLAvatar
          key={`${item?.user?.name}-reply-avatar`}
          slug={item?.user?.slug}
          avatar={item?.user.avatar}
          name={item?.user?.name}
        />
      </div>
      <div className="flex flex-col w-full ml-1 md:ml-3">
        <div className="flex flex-row justify-between h-6">
          <div className="flex flex-row w-full text-sm gap-1">
            <Link href={profileHref}>
              <div className="font-bold cursor-pointer">{item?.user?.name}</div>
            </Link>
            <ProBadge isPro={item?.user?.is_pro} />
            <div className="text-subtitle ml-2" title={fullDateTime}>
              {fromNowTime}
            </div>
          </div>
          {(item?.user.id === user?.id || isManager) &&
            !disableReplyActions && (
              <PostActions
                item={item}
                parentSlug={parentSlug}
                type={parentType}
                groupId={groupId}
              />
            )}
        </div>
        <ActivityContent item={item} collapsable={collapsable} isReply />
        <RenderResource item={item} />
        {item?.is_edited && <span className="text-subtitle mt-2">Edited</span>}
      </div>
    </div>
  )
}

export default Reply
