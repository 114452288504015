import React from 'react'
import useFollow from '../../../lib/useFollow'
import useUser from '../../../lib/useUser'
import { useAnalytics } from '../../context/AnalyticsProvider'
import PopoutButton from './PopoutButton'

const FollowButton = ({ item }) => {
  const { user } = useUser()
  const { trackData } = useAnalytics()

  const { isFollowing, createFollow, deleteFollow } = useFollow(
    item?.id,
    item?.type
  )

  if (user?.id === item?.user?.id || user?.id === item?.eventable?.id) {
    return null
  }

  const handleFollowing = async () => {
    if (isFollowing) {
      try {
        await deleteFollow()
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await createFollow()

        if (item?.type) {
          trackData(`${item?.type?.toLowerCase()}_follow`, {
            user,
            type: item?.type,
            item,
            followed_at: new Date().toISOString(),
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <PopoutButton onClick={handleFollowing}>
      {isFollowing ? 'Following' : 'Follow'}
    </PopoutButton>
  )
}

export default FollowButton
