const ReplyCTA = ({ itemArchived }) => {
  if (itemArchived) {
    return null
  }

  return (
    <p className="text-disabled text-tiny pb-3 pl-0 md:pl-2">
      No responses yet. Be the first to respond.
    </p>
  )
}

export default ReplyCTA
