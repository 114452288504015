import classnames from 'classnames'
import TopicPill from './TopicPill'

export default function TopicsRow({
  topics,
  alt = false,
  PrefixComponents,
  onTopicClick,
  activeTopic,
  containerStyle,
}) {
  if (
    (!topics || topics?.length <= 0) &&
    (!PrefixComponents || PrefixComponents?.length <= 0)
  ) {
    return null
  }

  const containerStyles = classnames('flex flex-row mb-4', containerStyle)

  return (
    <div className={containerStyles}>
      {PrefixComponents &&
        PrefixComponents?.map((Component, i) => {
          if (!Component) {
            return null
          }
          return (
            <div key={`prefix-${i}`} className="mr-2 max-w-full">
              {Component}
            </div>
          )
        })}
      <div className="horizontal-scroller scrollbar-hide flex rounded-full">
        {topics?.map((topic, i) => (
          <div key={`topic-${i}`} className="mr-2 max-w-full">
            <TopicPill
              name={topic.name}
              color={topic.color}
              alt={alt}
              onClick={onTopicClick}
              active={activeTopic === topic.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
