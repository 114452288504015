import { useFloating, useHover, useInteractions } from '@floating-ui/react'
import Image from 'next/image'
import { useState } from 'react'
import StampPopout from './StampPopout'

const StampCounter = ({
  name,
  avatar,
  description,
  count = 0,
  stampableId,
  onClick,
  canAwardStamp,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const hover = useHover(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  return (
    <div ref={refs.setReference} {...getReferenceProps()}>
      <div className="flex flex-row gap-1 cursor-pointer">
        {avatar && (
          <Image
            key={`${name}-stamp-counter-avatar`}
            src={avatar}
            alt={`${name} stamp avatar`}
            width={26}
            height={26}
            style={{
              maxWidth: 26,
              maxHeight: 26,
            }}
          />
        )}
        {count > 1 && <p className="text-sm opacity-50">{count}</p>}
      </div>
      {isOpen && (
        <StampPopout
          setRef={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          avatar={avatar}
          name={name}
          description={description}
          onClick={onClick}
          stampableId={stampableId}
          canAwardStamp={canAwardStamp}
        />
      )}
    </div>
  )
}

export default StampCounter
