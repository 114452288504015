import { yupResolver } from '@hookform/resolvers/yup'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSWRConfig } from 'swr'
import checkForFormChanges from '../../lib/checkForFormChanges'
import { useLeavePageConfirmation } from '../../lib/useLeavePageConfirmation'
import usePostCrud from '../../lib/usePostCrud'
import useReplyCrud from '../../lib/useReplyCrud'
import useUnfurl from '../../lib/useUnfurl'
import BasicErrorMessage from '../common/BasicErrorMessage'
import Button from '../common/Button'
import CLEditor from '../common/CLEditor'
import Icon from '../common/Icon'
import TextInput from '../common/TextInput'
import Unfurl from '../common/Unfurl'
import { useCacheKey } from '../context/CacheKey'
import AltTextInput from './common/AltTextInput'
import editPost from './defaults/editPost'
import editPostSchema from './schemas/editPost'
import replySchema from './schemas/reply'

export default function EditPost({
  toggleModal,
  item,
  parentSlug,
  parentType,
  setModalChangesWithInput,
}) {
  const { mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [changesWithInput, setChangesWithInput] = useState(false)
  const unfurl = useUnfurl(item)
  const isReply = parentSlug != null
  const { updateReply } = useReplyCrud(parentSlug)
  const { updatePost } = usePostCrud()
  const defaultValues = editPost(item)

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isReply ? replySchema : editPostSchema),
    defaultValues,
  })

  const watchFields = useWatch({ control })
  useLeavePageConfirmation(
    changesWithInput,
    'Are you sure you want to discard these changes?'
  )

  useEffect(() => {
    const isFieldChanged = checkForFormChanges(watchFields, defaultValues)
    setChangesWithInput(isFieldChanged)
    setModalChangesWithInput(isFieldChanged)
  }, [watchFields, defaultValues])

  const update = parentSlug == null ? updatePost : updateReply

  const onSubmit = async (data) => {
    setSubmitting(true)
    setErrorMsg(null)
    const { content } = data
    const body = {
      id: item?.id,
      slug: item?.slug,
      title: data?.title,
      content_html: content.html,
      content_delta: JSON.stringify(content.delta),
      type: parentType,
      alt_text: data?.altText,
    }
    try {
      await update(body)
      mutate(cacheKey.value)
      toggleModal()
      setSubmitting(false)
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setErrorMsg('There was an error editing your post')
      setSubmitting(false)
    }
    toggleModal()
  }

  const handleDiscard = async () => {
    const shouldDiscard =
      !changesWithInput ||
      (await window.confirm('Are you sure you want to discard these changes?'))

    if (shouldDiscard) {
      toggleModal()
      reset()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col w-full px-2 md:w-132"
    >
      {parentType !== 'Post' && (
        <TextInput
          name="title"
          label="Post Title"
          required
          hint={errors?.title?.message}
          register={register}
        />
      )}

      <Controller
        name="content"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CLEditor
            value={value?.delta}
            onChange={onChange}
            label="Post Text"
            required
            hint={errors?.content?.message || errors?.content?.html?.message}
            topicHint
            delta
            groupId={item?.postable?.id}
          />
        )}
      />

      {parentType !== 'Post' && (
        <ContentPreview
          item={item}
          unfurl={unfurl}
          register={register}
          getValues={getValues}
        />
      )}

      <BasicErrorMessage error={errorMsg} />

      <div className="flex flex-row flex-grow justify-end mt-5">
        <Button
          noPadding
          className="mr-2 px-5 py-2 text-off-black"
          onClick={handleDiscard}
        >
          Discard
        </Button>
        <input
          disabled={submitting}
          type="submit"
          value="Update Post"
          className={`px-8 py-2 rounded-full text-white bg-primary  ${
            submitting
              ? 'opacity-20 cursor-not-allowed'
              : 'opacity-100 cursor-pointer'
          }`}
        />
      </div>
    </form>
  )
}

function ContentPreview({ item, unfurl, register, getValues }) {
  return (
    <div className="mt-2">
      {item?.image && (
        <>
          <div className="relative w-full pb-96 mb-6">
            <Image
              src={item?.image}
              alt={item?.alt_text || 'Post image'}
              className="object-contain object-left-top w-full"
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
          <AltTextInput register={register} showText={getValues('altText')} />
        </>
      )}
      {item?.file && (
        <div className="flex flex-row items-center justify-between my-3 px-2">
          <b>Attachment:</b>
          <Button small primary href={item?.file}>
            <Icon name="paperclip" size={24} color="white" />
            Download file
          </Button>
        </div>
      )}
      {item?.link && unfurl && unfurl.url && (
        <div className="w-full flex mb-3 justify-center">
          <Unfurl
            link={unfurl.url}
            video={unfurl.video}
            image={unfurl.image}
            title={unfurl.title}
            description={unfurl.description}
          />
        </div>
      )}
    </div>
  )
}
