import produce from 'immer'

export default function eventCache(cache, mutate, cacheKey, eventSlug) {
  return {
    add(event) {
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null) {
            return
          }
          if (pages.length === 0) {
            pages[0] = { events: [] }
          }

          pages[0].items.unshift(event)
        }),
        false
      )
    },

    update(updater, userId) {
      mutate(
        `/api/events/${eventSlug}.json`,
        produce((data) => {
          if (data?.event == null) {
            return
          }
          data.event = produce(data.event, updater)
        }),
        false
      )
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null || pages.length === 0) {
            return
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < pages.length; i++) {
            const eventIndex = pages[i].items.findIndex(
              (p) => p.slug === eventSlug && p.type === 'Event'
            )
            if (eventIndex > -1) {
              pages[i].items[eventIndex] = produce(
                pages[i].items[eventIndex],
                updater
              )
              return
            }
          }
        }),
        false
      )
      mutate(`/api/users/${userId}/events.json`)
    },

    remove(userId) {
      cache.delete(`/api/events/${eventSlug}.json`)
      mutate(
        cacheKey.value,
        produce((pages) => {
          if (pages == null || pages.length === 0) {
            return
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < pages.length; i++) {
            const eventIndex = pages[i].items.findIndex(
              (p) => p.slug === eventSlug
            )
            if (eventIndex > -1) {
              pages[i].items.splice(eventIndex, 1)
              return
            }
          }
        }),
        false
      )
      mutate(`/api/users/${userId}/events.json`)
    },
  }
}
