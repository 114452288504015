import router from 'next/router'
import { useState } from 'react'
import useMemoizedPathFor from '../../lib/pathFor'
import useGroup from '../../lib/useGroup'
import { useLeavePageConfirmation } from '../../lib/useLeavePageConfirmation'
import usePopoutTriggerRef from '../../lib/usePopoutTriggerRef'
import usePostCrud from '../../lib/usePostCrud'
import useReplyCrud from '../../lib/useReplyCrud'
import useUser from '../../lib/useUser'
import Icon from '../common/Icon'
import BaseModal from '../common/modal/BaseModal'
import ConfirmModal from '../common/modal/ConfirmModal'
import AddToShowcase from '../common/popout/AddToShowcase'
import CopyButton from '../common/popout/CopyButton'
import FollowButton from '../common/popout/FollowButton'
import { Popout, PopoutContent, PopoutTrigger } from '../common/popout/Popout'
import PopoutButton from '../common/popout/PopoutButton'
import EditPost from '../forms/EditPost'

const PostActions = ({ groupId, item, parentSlug, type }) => {
  const id = parentSlug || item?.slug || item?.id
  const { popoutTriggerRef, popoutIsOpen } = usePopoutTriggerRef()
  const { user, isGroupManager } = useUser()
  const shareLink = `https://${window.location.host}${useMemoizedPathFor(
    'post',
    id
  )}`
  const isAuthor = user?.id === item?.user?.id
  const isManager = isGroupManager(groupId)
  const { canParticipate } = useGroup(
    item.postable_type?.toLowerCase() === 'group' && popoutIsOpen
      ? item.postable?.slug
      : null
  )
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [modalChangesWithInput, setModalChangesWithInput] = useState(false)
  const { deleteReply } = useReplyCrud(parentSlug)
  const { updatePost, deletePost } = usePostCrud()
  const deleteAction = parentSlug == null ? deletePost : deleteReply

  useLeavePageConfirmation(
    modalChangesWithInput && editVisible,
    'Are you sure you want to discard these changes?'
  )

  const onDelete = async () => {
    try {
      await deleteAction({ slug: item.slug, type })
      if (window.location.pathname.includes('/posts/')) {
        router.replace('/')
      }
    } catch (error) {
      console.error('An unexpected error happened:', error)
    }
  }

  return (
    <Popout>
      <PopoutTrigger ref={popoutTriggerRef}>
        <div className="flex flex-row items-center -mr-1.5 -my-2 lg:-my-1">
          <Icon name="more" color="offBlack" />
        </div>
      </PopoutTrigger>
      <PopoutContent>
        {!parentSlug && <CopyButton copyable={shareLink} label="Copy link" />}
        {!parentSlug && canParticipate && <FollowButton item={item} />}
        {!parentSlug && isManager && (
          <PopoutButton
            onClick={() =>
              updatePost({
                postable_type: item.postable_type,
                postable_id: item.postable_id,
                is_pinned: !item.is_pinned,
                slug: item.slug,
              })
            }
          >
            {item.is_pinned ? 'Unpin post' : 'Pin post'}
          </PopoutButton>
        )}
        <AddToShowcase
          id={item?.slug || item?.id}
          type={item?.type}
          visibility={item?.postable?.visibility}
          badges={item?.postable?.badges}
        />
        {isAuthor && (
          <>
            <PopoutButton onClick={() => setEditVisible(true)}>
              {parentSlug ? 'Edit reply' : 'Edit post'}
            </PopoutButton>
            <EditModal
              isOpen={editVisible}
              onRequestClose={() => setEditVisible(false)}
              item={item}
              parentSlug={parentSlug}
              parentType={type}
              modalChangesWithInput={modalChangesWithInput}
              setModalChangesWithInput={setModalChangesWithInput}
            />
          </>
        )}
        {(isAuthor || isManager) && (
          <>
            <PopoutButton
              className="text-topic-pink"
              onClick={() => setDeleteConfirmVisible(true)}
            >
              {parentSlug ? 'Delete reply' : 'Delete post'}
            </PopoutButton>
            <ConfirmModal
              title={parentSlug ? 'Delete reply?' : 'Delete post?'}
              subtitle={
                item?.postable?.name
                  ? `Are you sure you want to permanently remove this ${
                      parentSlug ? 'reply' : 'post'
                    } from ${item?.postable?.name}`
                  : `Are you sure you want to permanently remove this ${
                      parentSlug ? 'reply' : 'post'
                    }`
              }
              actionLabel="Delete"
              danger
              cancelLabel="Cancel"
              // Here we use slug's for Post's cache key and id's for the replies
              action={onDelete}
              isOpen={deleteConfirmVisible}
              onRequestClose={() => setDeleteConfirmVisible(false)}
            />
          </>
        )}
      </PopoutContent>
    </Popout>
  )
}

function EditModal({
  isOpen,
  onRequestClose,
  item,
  parentType,
  parentSlug,
  modalChangesWithInput,
  setModalChangesWithInput,
}) {
  const handleCloseEditModal = async () => {
    if (modalChangesWithInput && isOpen) {
      const confirm = await window.confirm(
        'Are you sure you want to discard these changes?'
      )
      if (confirm) {
        onRequestClose()
      }
    } else {
      onRequestClose()
    }
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={handleCloseEditModal}>
      <EditPost
        item={item}
        toggleModal={onRequestClose}
        parentSlug={parentSlug}
        parentType={parentType}
        setModalChangesWithInput={setModalChangesWithInput}
      />
    </BaseModal>
  )
}

export default PostActions
