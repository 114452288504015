import useSWR from 'swr'
import fetchJson from './fetchJson'
import useMutators from './useMutators'

export default function useFollow(id, type) {
  const {
    error,
    data,
    mutate: mutateFollow,
    isValidating,
  } = useSWR('/api/follows')
  const follows = data?.follows

  const api = useMutators(mutateFollow, {
    createFollow: createFollow.bind(this, id, type),
    deleteFollow: deleteFollow.bind(this, id, type),
  })

  return {
    follows,
    mutateFollow,
    isValidating,
    error,
    ...api,
    get isFollowing() {
      return follows?.find(
        (element) =>
          element.followable_id === id &&
          element.followable_type.toLowerCase() === type.toLowerCase()
      )
    },
  }
}

export async function createFollow(id, type) {
  return await fetchJson(`/api/follows.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      follow: {
        followable_id: id,
        followable_type: type,
      },
    }),
  })
}

export async function deleteFollow(id, type) {
  return await fetchJson(`/api/follow.json`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      follow: {
        followable_id: id,
        followable_type: type,
      },
    }),
  })
}
