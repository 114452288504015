import useSWR from 'swr'
import createFormData from './createFormData'
import fetchJson from './fetchJson'
import useMutators from './useMutators'

export default function useBadge(slug) {
  const url = slug ? `/api/badges/${slug}.json` : null

  const { error, data, mutate: mutateBadge, isValidating } = useSWR(url)
  const badge = data?.badge

  const api = useMutators(mutateBadge, {
    giveBadge: giveBadge.bind(this, badge?.id),
    deleteBadging: deleteBadging.bind(this, badge?.slug),
    updateBadge,
    createBadge,
    addToShowcase,
    removeFromShowcase,
    deleteBadge,
  })

  return {
    badge,
    mutateBadge,
    isValidating,
    error,
    ...api,
    get inviteLink() {
      return `https://${window.location.host}/badges/${slug}?bid=${badge?.id}`
    },
  }
}

export async function giveBadge(badgeId, type, userId, isDistributor) {
  const body = { badging: { type, id: userId, is_distributor: isDistributor } }

  return await fetchJson(`/api/badges/${badgeId}/badgings.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function deleteBadging(badgeId, type, userId) {
  const body = { badgeable_type: type, badgeable_id: userId }

  return await fetchJson(`/api/badges/${badgeId}/badgings.json`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function updateBadge(badge) {
  const formData = createFormData({ badge })

  return await fetchJson(`/api/badges/${badge.slug}`, {
    method: 'PUT',
    body: formData,
  })
}

export async function createBadge(badge) {
  const formData = createFormData({ badge })

  return await fetchJson(`/api/badges`, {
    method: 'POST',
    body: formData,
  })
}

export async function addToShowcase(showcase, badgeSlug) {
  const formData = createFormData({ showcase })

  return await fetchJson(`/api/badges/${badgeSlug}/showcases.json`, {
    method: 'POST',
    body: formData,
  })
}

export async function removeFromShowcase(badgeId, showcaseId) {
  return await fetchJson(
    `/api/badges/${badgeId}/showcases/${showcaseId}.json`,
    {
      method: 'DELETE',
    }
  )
}

export async function deleteBadge(slug) {
  return await fetchJson(`/api/badges/${slug}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  })
}
