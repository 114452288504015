import usePaginated from './api/usePaginated'

export default function useStamps(filters = {}, shouldFetch = true, ) {
  const { items, ...attributes } = usePaginated(
    () => (shouldFetch ? '/api/stamps' : null),
    (response) => response?.stamps,
    filters,
    {
      refreshInterval: 3600000,
      dedupingInterval: 3600000,
    }
  )

  return {
    stamps: items,
    ...attributes,
  }
}
