import { useSWRConfig } from 'swr'
import { useCacheKey } from '../components/context/CacheKey'
import eventCache from './cache/eventCache'
import postCache from './cache/postCache'
import fetchJson from './fetchJson'

export default function useUpvoteCrud() {
  const { cache, mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  return {
    createUpvote: async (upvote, itemSlug = null) => {
      try {
        await postUpvote(upvote)
      } catch (err) {
        return
      }
      switch (upvote.reactableType) {
        case 'Post':
          postCache(cache, mutate, cacheKey, itemSlug || upvote.reactableId).update((post) =>
            addUpvote(post)
          )
          break
        case 'Event':
          eventCache(cache, mutate, cacheKey, itemSlug || upvote?.reactableId).update(
            (event) => addUpvote(event)
          )
          break
        default:
          throw new Error('Unknown reactable')
      }
    },
    removeUpvote: async (upvote, itemSlug) => {
      try {
        await deleteUpvote(upvote)
      } catch (err) {
        return
      }

      switch (upvote.reactableType) {
        case 'Post':
          postCache(cache, mutate, cacheKey, itemSlug || upvote.reactableId).update((post) =>
            removeUpvote(post)
          )
          break
        case 'Event':
          eventCache(cache, mutate, cacheKey, itemSlug || upvote?.reactableId).update(
            (event) => removeUpvote(event)
          )
          break
        default:
          throw new Error('Unknown reactable')
      }
    },
  }
}

async function postUpvote(data) {
  return await fetchJson(`/api/reactions.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      reaction: {
        name: data.name,
        reactable_id: data.reactableId,
        reactable_type:
          data.reactableType[0].toUpperCase() + data.reactableType.substring(1),
      },
    }),
  })
}

async function deleteUpvote(data) {
  return await fetchJson(`/api/reaction/${data.name}.json`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      reaction: {
        reactable_id: data.reactableId,
        reactable_type:
          data.reactableType[0].toUpperCase() + data.reactableType.substring(1),
      },
    }),
  })
}

function addUpvote(item) {
  return {
    ...item,
    upvote_count: item?.upvote_count + 1,
    user_has_upvoted: true,
  }
}

function removeUpvote(item) {
  return {
    ...item,
    upvote_count: item?.upvote_count - 1,
    user_has_upvoted: false,
  }
}
