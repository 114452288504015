import Image from 'next/image'
import { useEffect, useState } from 'react'
import useStampCrud from '../../../lib/useStampCrud'
import useUser from '../../../lib/useUser'
import { useAnalytics } from '../../context/AnalyticsProvider'

const SelectedStamp = ({
  item,
  stampableId,
  stampableType,
  selectedStamp,
  onRequestClose,
}) => {
  const { user } = useUser()
  const { trackData, trackUrl } = useAnalytics()
  const [error, setError] = useState()
  const { createStamp } = useStampCrud()
  const suggestLinkId = 'suggestStamp'

  const award = async () => {
    setError(null)
    try {
      const response = await createStamp(
        selectedStamp,
        stampableType,
        stampableId,
        item?.slug
      )

      if (stampableType) {
        trackData(`${stampableType?.toLowerCase()}_stamped`, {
          type: stampableType,
          item,
          user,
          stamp: response?.stamping,
        })
      }

      onRequestClose()
    } catch (error) {
      setError('Unable to award stamp')
    }
  }

  useEffect(() => {
    setError(null)
  }, [selectedStamp])

  useEffect(() => {
    trackUrl(suggestLinkId)
  }, [])

  return (
    <>
      {selectedStamp?.logo && (
        <Image
          key={`${selectedStamp?.name}-selected-stamp-avatar`}
          src={selectedStamp?.logo}
          alt={`${selectedStamp?.name} stamp avatar`}
          width={135}
          height={135}
          style={{
            maxWidth: 135,
            height: 135,
          }}
        />
      )}
      <p className="text-bold text-xl mt-8 mb-1">{selectedStamp?.name}</p>
      <p className="opacity-70 px-8 mb-11">{selectedStamp?.description}</p>
      {renderAction(
        item?.stamps,
        stampableId,
        user?.id,
        selectedStamp?.id,
        award
      )}
      {error && <p className="text-red-600 text-sm">{error}</p>}
      <a
        id={suggestLinkId}
        href="https://community-lab.upvoty.com/b/impact-stamps/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-disabled text-micro text-center"
      >
        Suggest a Stamp
      </a>
    </>
  )
}

export default SelectedStamp

const renderAction = (stamps, stampableId, userId, selectedStampId, award) => {
  if (
    !stampableId ||
    !stamps ||
    Object.keys(stamps).find(
      (s) => parseInt(s) === selectedStampId && stamps[s].users.includes(userId)
    )
  ) {
    return <span className="text-sm mb-4">Unable to award</span>
  }

  return (
    <button
      onClick={award}
      className="bg-topic-green py-2 px-6 rounded-3xl text-white text-tiny text-center font-medium mb-2"
    >
      Award this Stamp!
    </button>
  )
}
