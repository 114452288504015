import { useRouter } from 'next/router'
import React from 'react'
import useMemoizedPathFor from '../../lib/pathFor'
import useGroup from '../../lib/useGroup'
import Icon from './Icon'

const Breadcrumb = ({ referrer }) => {
  const router = useRouter()
  const { message, groupslug } = router.query
  const { group } = useGroup(groupslug)
  const groupHref = useMemoizedPathFor('group', group)

  if (!referrer || !group) {
    return null
  }

  let backArrowPath = groupHref
  const truncateText = (text) =>
    text?.length > 29 ? `${text?.substring(0, 27)}...` : text
  const truncatedGroup = truncateText(group?.name)
  let content = null

  if (message) {
    const truncatedMessage = truncateText(message)
    backArrowPath = `${groupHref}/resources?scroll=${message}`

    content = (
      <>
        <StyledText
          text={truncatedMessage}
          action={() =>
            router.replace(`${groupHref}/resources?scroll=${message}`)
          }
        />{' '}
        <b className="text-gray-600"> - in -</b>{' '}
        <StyledText
          text={truncatedGroup}
          action={() => router.replace(`${groupHref}`)}
        />
      </>
    )
  } else {
    content = (
      <StyledText
        text={truncatedGroup}
        action={() => router.replace(`${groupHref}`)}
      />
    )
  }

  return (
    <span className="text-black font-bold text-left cursor-pointer w-full max-w-full flex flex-row flex-wrap items-center gap-1">
      <div
        onClick={() => router.replace(backArrowPath)}
        onKeyDown={() => router.replace(backArrowPath)}
        tabIndex={0}
        role="button"
        aria-label="Back to previous page"
      >
        <Icon
          name="chevron-right"
          className="transform rotate-180"
          color="black"
        />
      </div>
      <span className="w-fit flex-shrink-0 ">Back to </span>
      {content}
    </span>
  )
}

const StyledText = ({ text, action }) => (
  <span
    onClick={action}
    onKeyDown={action}
    tabIndex={0}
    role="button"
    className="break-all truncate underline"
  >
    {text}
  </span>
)

export default Breadcrumb
