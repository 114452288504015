export function canShowcase({ type, visibility }) {
  if (
    (type?.toLowerCase() === 'group' ||
      type?.toLowerCase() === 'post' ||
      type?.toLowerCase() === 'event' ||
      type?.toLowerCase() === 'resource') &&
    visibility?.toLowerCase() !== 'everyone'
  ) {
    return false
  }

  return true
}

export function getShowcaseBadges({ badges = [], user }) {
  return badges.filter((itemBadge) =>
    user?.managed_badges.find((userBadge) => itemBadge?.id === userBadge?.id)
  )
}
