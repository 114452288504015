import { useSWRConfig } from 'swr'
import { useCacheKey } from '../components/context/CacheKey'
import eventCache from './cache/eventCache'
import postCache from './cache/postCache'
import fetchJson from './fetchJson'

export default function useStampCrud() {
  const { cache, mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  return {
    createStamp: async (selectedStamp, stampableType, stampableId, slug) => {
      const stampable = await awardStamp(
        selectedStamp?.id,
        stampableType,
        stampableId
      )

      switch (stampableType.toLowerCase()) {
        case 'post':
          postCache(cache, mutate, cacheKey, slug).update((post) =>
            addStamp(post, selectedStamp, stampable)
          )
          break
        case 'event':
          eventCache(cache, mutate, cacheKey, slug).update((event) =>
            addStamp(event, selectedStamp, stampable)
          )
          break
        default:
          throw new Error('Unknown stamp')
      }

      return stampable
    },
  }
}

export async function awardStamp(id, stampableType, stampableId) {
  const body = {
    stamping: {
      stamp_id: id,
      stampable_type: stampableType,
      stampable_id: stampableId,
    },
  }

  return await fetchJson(`/api/stampings.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

function addStamp(item, selectedStamp, stampable) {
  return {
    ...item,
    stamps: {
      ...item?.stamps,
      [selectedStamp?.id]: {
        count: item?.stamps[selectedStamp?.id]?.count + 1 || 0,
        users: [
          stampable?.creator?.id,
          ...(item?.stamps[selectedStamp]?.users || []),
        ],
      },
    },
  }
}
