import Image from 'next/image'
import useStamps from '../../lib/useStamps'
import BaseModal from '../common/modal/BaseModal'

const StampListModal = ({
  isOpen,
  onRequestClose,
  setAwardModalIsOpen,
  item,
}) => {
  const { stamps } = useStamps({ items: 20 }, isOpen)

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose()
      }}
      closeIcon
      title="Impact Stamps awarded:"
      titleStyle="w-full text-center"
    >
      <div className="w-full flex flex-col justify-start items-center gap-5">
        {item?.stamps &&
          Object.keys(item.stamps).map((key) => {
            const stamp = stamps?.find((s) => s.id === parseInt(key))

            return (
              <div
                key={`${stamp?.slug}-awarded-stamp`}
                className="flex flex-row gap-6 w-3/4"
              >
                {stamp?.logo && (
                  <div>
                    <Image
                      key={`${stamp?.name}-stamp-list-avatar`}
                      src={stamp?.logo}
                      alt={`${stamp?.name} stamp avatar`}
                      width={60}
                      height={60}
                      style={{
                        maxWidth: 60,
                        maxHeight: 60,
                      }}
                    />
                  </div>
                )}
                <div>
                  <div className="flex flex-row gap-1 font-bold mb-1 ">
                    <p className="text-black">{stamp?.name}</p>
                    {item?.stamps[key]?.count > 1 && (
                      <p className="text-subtitle">
                        x{item?.stamps[key]?.count}
                      </p>
                    )}
                  </div>
                  <p className="opacity-90 text-tiny">{stamp?.description}</p>
                </div>
              </div>
            )
          })}
        <button
          onClick={() => {
            onRequestClose()
            setAwardModalIsOpen(true)
          }}
          className="text-topic-blue text-center mt-2"
        >
          Award an Impact Stamp!
        </button>
      </div>
    </BaseModal>
  )
}

export default StampListModal
