import Link from 'next/link'
import pluralize from 'pluralize'
import useMemoizedPathFor from '../../lib/pathFor'
import Button from '../common/Button'
import Reply from './Reply'

const Replies = ({
  count,
  replies,
  parentSlug,
  parentType,
  groupId,
  dontShowAll,
  disableReplyActions = false,
}) => {
  const parentHref = useMemoizedPathFor(parentType, parentSlug)
  
  return (
  <div className="flex flex-col px-0 lg:px-2 py-3">
    <Link href={parentHref}>
      <span className="text-subtitle text-tiny cursor-pointer">
        {count} {pluralize('Response', count)}
      </span>
    </Link>
    {replies.map((reply) => (
      <Reply
        item={reply}
        parentSlug={parentSlug}
        parentType={parentType}
        key={`reply-${reply.id}`}
        groupId={groupId}
        disableReplyActions={disableReplyActions}
      />
    ))}
    {count > 6 && !dontShowAll && (
      <div className="flex justify-center md:justify-end">
        <Button
          noPadding
          className="px-6 py-2 text-offBlack"
          href={parentHref}
        >
          Show all responses
        </Button>
      </div>
    )}
  </div>
)}

export default Replies
