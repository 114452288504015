import * as yup from 'yup'
import richContent from './richContent'

export default yup.object().shape({
  isImage: yup.boolean(),
  isLink: yup.boolean(),
  isFile: yup.boolean(),
  content: yup.object().when(['isImage', 'isLink', 'isFile'], {
    is: (isImage, isLink, isFile) => isImage || isLink || isFile,
    then: yup.object().nullable(),
    otherwise: richContent('Message text', 1),
  }),
  link: yup.lazy((value) =>
    !value
      ? yup.string().nullable()
      : yup.string().url('Link must be a valid url')
  ),
})
