import useUpvoteCrud from '../../lib/useUpvoteCrud'
import useUser from '../../lib/useUser'
import Icon from '../common/Icon'
import { useAnalytics } from '../context/AnalyticsProvider'

const UpvoteButton = ({ hasVoted, type, id, itemSlug, item, count }) => {
  const { createUpvote, removeUpvote } = useUpvoteCrud()
  const { user } = useUser()
  const { trackData } = useAnalytics()

  const toggleUpvote = () => {
    if (hasVoted) {
      removeUpvote(
        {
          name: 'upvote',
          reactableId: id,
          reactableType: type,
        },
        itemSlug
      )
    } else {
      createUpvote(
        {
          name: 'upvote',
          reactableId: id,
          reactableType: type,
        },
        itemSlug
      )

      if (item) {
        const mixpanelEvent = type ? `${type?.toLowerCase()}_upvote` : 'upvote'
        trackData(mixpanelEvent, { type, item, user })
      }
    }
  }

  return (
    <div
      className={`w-10 py-1 flex flex-col justify-center items-center rounded-md ${
        hasVoted ? 'bg-topic-green' : 'bg-alt-bg'
      }`}
      onClick={toggleUpvote}
      onKeyPress={toggleUpvote}
      role="button"
      tabIndex="0"
    >
      <Icon name="upvote" color={hasVoted ? 'white' : 'offBlack'} size={28} />
      <hr className={`my-1 w-full ${hasVoted ? 'bg-white' : 'bg-subtitle'}`} />
      <p
        className={`text-tiny pb-2 font-bold ${
          hasVoted ? 'text-white' : 'text-offBlack'
        }`}
      >
        {count || 0}
      </p>
    </div>
  )
}

export default UpvoteButton
